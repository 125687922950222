<template>
  <div class="w-full px-8">
    <div class="mt-12 flex justify-between items-center gap-x-8">
      <div class="w-1/3">
        <div class="bg-white border border-gray-soft w-full h-full p-8 rounded">
          <span class="block text-headline-5 font-semibold" v-show="TOTAL_ALL_USER_READY">+ {{ GET_TOTAL_ALL_USER.total }}</span>
          <Loading v-show="!TOTAL_ALL_USER_READY" />
          <span class="block text-base text-gray-500">Total User</span>
        </div>
      </div>
      <div class="w-1/3">
        <div class="bg-white border border-gray-soft w-full h-full p-8 rounded">
          <span class="block text-headline-5 font-semibold" v-show="TOTAL_NEW_USER_READY">+ {{ GET_TOTAL_NEW_USER.total }}</span>
          <Loading v-show="!TOTAL_NEW_USER_READY" />
          <span class="block text-base text-gray-500">New Subscription</span>
        </div>
      </div>
      <div class="w-1/3">
        <div class="bg-white border border-gray-soft w-full h-full p-8 rounded">
          <span class="block text-headline-5 font-semibold" v-show="TOTAL_PUBLISHED_ARTICLE_STATE">+ {{ GET_TOTAL_PUBLISHED_ARTICLE.total }}</span>
          <Loading v-show="!TOTAL_PUBLISHED_ARTICLE_STATE" />
          <span class="block text-base text-gray-500">Created Research</span>
        </div>
      </div>
    </div>
    <div class="my-5 flex gap-x-2 justify-center" v-if="false">
      <div class="w-9/12">
        <InputDropdown
          class="bg-white w-full h-full p-2 rounded"
          :options="statGroupOptions"
          v-model="selectedStatGroup"
          hide-errors
          flat
        ></InputDropdown>
      </div>
      <div class="w-3/12">
        <InputDropdown
          class="bg-white w-full h-full p-2 rounded"
          :options="timeRangeOptions"
          v-model="selectedTimeRange"
          hide-errors
          flat
        ></InputDropdown>
      </div>
    </div>
    <div class="mt-5 bg-white rounded pt-8" v-if="visitorChartReady">
      <div class="block w-full mb-12 px-8">
        <div class="grid grid-cols-2">
          <div>
            <strong><u>{{ getVisitorTypeLabel() }} unique vistor</u></strong>
          </div>
          <div class="text-right">
            <select class="shadow rounded" v-model="visitorChartType">
              <option v-for="opt in visitorChartTypes" :key="opt.value" :value="opt.value" >{{opt.label}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="block w-full mb-12 px-8">
        <BarChart
        :chart-data="userTrackerData"
        :top-counter="false"
        :show-legends="false"
      ></BarChart>
      </div>
    </div>
    <div class="mt-5 bg-white rounded pt-8" v-if="false">
      <div class="block w-full mb-12 px-8">
        <div class="float-left">
          <svg
            class="inline-block align-middle"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="48"
              height="48"
              rx="24"
              fill="#48D5D1"
              fill-opacity="0.12"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.8766 27.2062C18.0326 27.2062 14.7496 27.7872 14.7496 30.1152C14.7496 32.4432 18.0126 33.0452 21.8766 33.0452C25.7216 33.0452 29.0036 32.4632 29.0036 30.1362C29.0036 27.8092 25.7416 27.2062 21.8766 27.2062Z"
              stroke="#48D5D1"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.8766 23.8859C24.3996 23.8859 26.4446 21.8409 26.4446 19.3179C26.4446 16.7949 24.3996 14.7499 21.8766 14.7499C19.3546 14.7499 17.3096 16.7949 17.3096 19.3179C17.3006 21.8319 19.3306 23.8769 21.8456 23.8859H21.8766Z"
              stroke="#48D5D1"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M31.2037 20.6691V24.6791"
              stroke="#48D5D1"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M33.2497 22.674H29.1597"
              stroke="#48D5D1"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div class="inline-block align-middle px-4">
            <span class="block text-headline-5 font-semibold"
              >+ {{ graphCounter.value }}</span
            >
            <span class="block text-base text-gray-500"
              >Total {{ graphCounter.label }}</span
            >
          </div>
        </div>
        <div class="float-right">
          <ButtonSolidCircle>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.59 9H15V4C15 3.45 14.55 3 14 3H10C9.45 3 9 3.45 9 4V9H7.41C6.52 9 6.07 10.08 6.7 10.71L11.29 15.3C11.68 15.69 12.31 15.69 12.7 15.3L17.29 10.71C17.92 10.08 17.48 9 16.59 9ZM5 19C5 19.55 5.45 20 6 20H18C18.55 20 19 19.55 19 19C19 18.45 18.55 18 18 18H6C5.45 18 5 18.45 5 19Z"
                fill="white"
              />
            </svg>
          </ButtonSolidCircle>
        </div>
        <div class="clear-both"></div>
      </div>
      <BarChart
        :chart-data="chartData"
        :top-counter="graphCounter"
        :show-legends="isGraphShowLegends"
      ></BarChart>
    </div>
  </div>
</template>

<script>
import InputDropdown from '@/components/Inputs/InputDropdown.vue'
import Loading from '@/components/Loading.vue'
import ButtonSolidCircle from '@/components/Buttons/ButtonSolidCircle.vue'
import BarChart from '@/components/Charts/BarChart.vue'
import { createNamespacedHelpers } from 'vuex'

const userStatistics = createNamespacedHelpers('userStatistics')
const internalUser = createNamespacedHelpers('internalUser')
const articleStatistics = createNamespacedHelpers('articleStatistics')
const articleVisitor = createNamespacedHelpers('articleVisitor')

export default {
  components: {
    InputDropdown: InputDropdown,
    ButtonSolidCircle: ButtonSolidCircle,
    BarChart: BarChart,
    Loading: Loading
  },
  data () {
    return {
      visitorChartType: 'THISYEAR',
      visitorChartTypes: [
        {
          value: 'LAST7DAYS',
          label: 'Last 7 days'
        },
        {
          value: 'LAST2WEEKS',
          label: 'Last 14 days'
        },
        {
          value: 'THISMONTH',
          label: 'This month'
        },
        {
          value: 'LASTMONTH',
          label: 'Last month'
        },
        {
          value: 'THISYEAR',
          label: 'This year'
        },
        {
          value: 'LASTYEAR',
          label: 'Last year'
        },
        {
          value: 'LAST12MONTHS',
          label: 'Last 12 months'
        }
      ],
      visitorChartReady: false,
      statGroupOptions: [
        {
          text: 'Active User',
          value: 'active-user',
          displayType: 'bar'
        },
        {
          text: 'Income',
          value: 'income',
          displayType: 'bar'
        },
        {
          text: 'Read by Time',
          value: 'read-by-time',
          displayType: 'bar-group'
        },
        {
          text: 'Active Subscriber',
          value: 'active-subscriber',
          displayType: 'bar-group'
        }
      ],
      timeRangeOptions: [
        {
          text: '3 Month',
          value: 3
        },
        {
          text: '6 Month',
          value: 6
        },
        {
          text: '1 Year',
          value: 12
        }
      ],
      selectedStatGroup: {
        text: 'Active User',
        value: 'active-user',
        displayType: 'bar'
      },
      selectedTimeRange: {
        text: '6 Month',
        value: 6
      },
      chartData: null,
      userTrackerData: this.initialVisitorChartData()
    }
  },
  computed: {
    ...internalUser.mapGetters(['GET_STATUS']),
    ...userStatistics.mapGetters(['GET_TOTAL_NEW_USER', 'TOTAL_NEW_USER_READY', 'GET_TOTAL_ALL_USER', 'TOTAL_ALL_USER_READY']),
    ...articleStatistics.mapGetters(['GET_TOTAL_PUBLISHED_ARTICLE', 'TOTAL_PUBLISHED_ARTICLE_STATE']),
    ...articleVisitor.mapGetters(['GET_VISITOR_DATA', 'GET_VISITOR_STATE']),
    graphCounter () {
      let counterPayload = null
      switch (this.selectedStatGroup.value) {
        case 'active-subscriber':
          counterPayload = {
            label: this.selectedStatGroup.text,
            value: 80
          }
          break
        case 'active-user':
          counterPayload = {
            label: this.selectedStatGroup.text,
            value: 80
          }
          break
        case 'read-by-time':
          counterPayload = {
            label: this.selectedStatGroup.text,
            value: 80
          }
          break
        case 'income':
          counterPayload = {
            label: this.selectedStatGroup.text,
            value: '81.000 IDR'
          }
          break
        default:
          break
      }
      return counterPayload
    },
    isGraphShowLegends () {
      return (
        Object.prototype.hasOwnProperty.call(
          this.selectedStatGroup,
          'displayType'
        ) && this.selectedStatGroup.displayType === 'bar-group'
      )
    }
  },
  watch: {
    selectedStatGroup: {
      handler (selectedOption) {
        this.updateGraph(selectedOption)
      }
    },
    visitorChartType: {
      handler (_) {
        console.log('type changed')
        this.getVisitorChartData()
      }
    }
  },
  created () {
    if (localStorage.getItem('access_token') === null) {
      this.$router.push({ name: 'route.root' })
    } else {
      this.updateGraph(this.selectedStatGroup)
      this.getUserStatus()
      this.getVisitorChartData()
    }
  },
  methods: {
    ...userStatistics.mapActions(['getTotalNewUser', 'getTotalAllUser']),
    ...internalUser.mapActions(['getStatus']),
    ...articleStatistics.mapActions(['getPublishedArticle']),
    ...articleVisitor.mapActions(['getVisitoStatistic']),
    getVisitorTypeLabel () {
      for (var x = 0; x < this.visitorChartTypes.length; x++) {
        if (this.visitorChartTypes[x].value === this.visitorChartType) {
          return this.visitorChartTypes[x].label
        }
      }
    },
    getVisitorChartData () {
      this.visitorChartReady = false
      Object.assign(this.userTrackerData, this.initialVisitorChartData())
      this.getVisitoStatistic({ filter: { type: this.visitorChartType } }).then((result) => {
        if (Array.isArray(result.data.visitor) && result.data.visitor.length > 0) {
          result.data.visitor.forEach((data) => {
            if (['THISYEAR', 'LASTYEAR'].includes(this.visitorChartType)) {
              this.userTrackerData.labels.push(this.moment.monthsShort(data.month_visited - 1))
            } else if (['LAST12MONTHS'].includes(this.visitorChartType)) {
              this.userTrackerData.labels.push(`${this.moment.monthsShort(data.month_visited - 1)}-${data.year_visited}`)
            } else {
              this.userTrackerData.labels.push(`${data.day_visited}-${this.moment.monthsShort(data.month_visited - 1)}-${data.year_visited}`)
            }
            this.userTrackerData.datasets[0].data.push(data.visitor)
          })
        }
        this.visitorChartReady = true
      })
    },
    initialVisitorChartData () {
      return {
        labels: [],
        datasets: [
          {
            label: 'Visit',
            backgroundColor: '#296DDB',
            data: []
          }
        ]
      }
    },
    async getUserStatus () {
      const result = await this.getStatus()
        .catch((err) => {
        // console.log('Get User Status Error')
        // console.log(err)
          this.catchError(err)
        })
      // console.log(result.data)
      if (result.data.role === 'admin' || result.data.role === 'owner' || result.data.role === 'auditor' || result.data.role === 'writter') {
        this.getData()
      } else {
        // console.log('User do not have suficient access, rerouting to article module')
        this.$router.push({ name: 'route.dashboard.article' })
      }
    },
    getData () {
      var firstDay = this.moment().startOf('month').format('YYYY-MM-DD')
      var lastDay = this.moment().endOf('month').format('YYYY-MM-DD')
      this.getTotalAllUser()
        .catch((err) => {
          this.catchError(err)
        })
      this.getTotalNewUser({ filter: { start_date: firstDay, end_date: lastDay } })
        .catch((err) => {
          this.catchError(err)
        })
      this.getPublishedArticle({ filter: { start_date: firstDay, end_date: lastDay } })
        .catch((err) => {
          this.catchError(err)
        })
    },
    catchError (err) {
      // if (err.response !== undefined && err.response.status === 401) {
      //   localStorage.removeItem('access_token')
      //   this.$router.push({ name: 'route.root' })
      // } else
      if (err.response !== undefined && err.response.status === 403) {
        this.$router.push({ name: 'route.dashboard.home' })
      }
    },
    getRandomInt () {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },
    updateGraph (selectedOption) {
      this.chartData = this.changeStats(selectedOption.value)
    },
    changeStats (groupName) {
      const loadedChartData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June'],
        datasets: []
      }
      switch (groupName) {
        case 'active-subscriber':
          loadedChartData.datasets.push({
            label: 'Morning',
            backgroundColor: '#296DDB',
            data: [
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt()
            ]
          })
          loadedChartData.datasets.push({
            label: 'Noon',
            backgroundColor: '#48D5D1',
            data: [
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt()
            ]
          })
          loadedChartData.datasets.push({
            label: 'Afternoon',
            backgroundColor: '#F78522',
            data: [
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt()
            ]
          })
          break
        case 'read-by-time':
          loadedChartData.datasets.push({
            label: 'Morning',
            backgroundColor: '#296DDB',
            data: [
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt()
            ]
          })
          loadedChartData.datasets.push({
            label: 'Noon',
            backgroundColor: '#48D5D1',
            data: [
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt()
            ]
          })
          loadedChartData.datasets.push({
            label: 'Afternoon',
            backgroundColor: '#F78522',
            data: [
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt()
            ]
          })
          break
        case 'income':
          loadedChartData.datasets.push({
            label: 'Income',
            backgroundColor: '#48D5D1',
            data: [
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt()
            ]
          })
          break
        case 'active-user':
          loadedChartData.datasets.push({
            label: 'New User',
            backgroundColor: '#48D5D1',
            data: [
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt()
            ]
          })
          break
        default:
          break
      }
      return loadedChartData
    }
  }
}
</script>
