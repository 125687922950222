<template>
  <div class="relative">
    <div class="absolute left-1/2 -top-6 mx-auto" v-if="!!topCounter">
      <div class="relative -left-1/2 text-center">
        <div class="bg-white border border-gray-100 px-8 py-2 rounded z-10">
          <span class="block font-semibold text-subtitle-1">{{
            topCounter.value
          }}</span>
          <span class="block leading-4 font-base text-overline text-gray-500">{{
            topCounter.label
          }}</span>
        </div>
      </div>
    </div>
    <div class="px-8">
      <BarChartWrapper
        ref="barChart"
        :chart-data="chartData"
        :options="combinedOptions"
      ></BarChartWrapper>
    </div>
    <div class="flex flex-col">
      <div class="w-full h-0.5 bg-gray-100 my-5"></div>
      <div
        class="legend-container mb-8"
        :class="{ 'opacity-0': !this.showLegends }"
        v-html="legendElement"
      ></div>
    </div>
  </div>
</template>

<script>
import BarChartWrapper from '@/components/Charts/BarChartWrapper.vue'

export default {
  components: {
    BarChartWrapper: BarChartWrapper
  },
  props: {
    chartData: {},
    options: {},
    topCounter: {},
    showLegends: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      defaultOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
          labels: {}
        },
        scales: {
          xAxes: [
            {
              barPercentage: 0.4,
              ticks: {
                padding: 40
              },
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                // stepSize: 20,
                beginAtZero: true
              },
              gridLines: {
                zeroLineColor: 'transparent',
                drawBorder: false,
                drawTicks: false,
                color: '#F5F5F5',
                display: true
              }
            }
          ]
        },
        legendCallback: chart => {
          const renderLabels = chart => {
            const { data } = chart

            return data.datasets
              .map(
                (_, i) =>
                  `
                    <li id="legend-${i}-item" class="mx-2 align-middle">
                      <span class="mx-1 inline-block rounded-full w-3 h-3" style="background-color:
                        ${data.datasets[i].backgroundColor};">
                      </span>
                      <span class="inline-block font-caption text-base text-gray-500 ">
                        ${data.datasets[i].label}
                      </span>
                    </li>
              `
              )
              .join('')
          }
          return `
          <ul class="chartjs-legend">
            ${renderLabels(chart)}
          </ul>`
        }
      },
      legendElement: ''
    }
  },
  watch: {
    chartData: {
      handler () {
        this.updateLegendElement()
      },
      deep: true
    }
  },
  mounted () {
    this.updateLegendElement()
  },
  computed: {
    combinedOptions () {
      const overrideOptions = this.defaultOptions
      overrideOptions.legend.display = false
      return {
        ...overrideOptions,
        ...this.options
      }
    }
  },
  methods: {
    updateLegendElement () {
      this.$nextTick(() => {
        this.legendElement = this.generateLegendElement()
      })
    },
    generateLegendElement () {
      return this.$refs.barChart.$data._chart.generateLegend()
    }
  }
}
</script>

<style scoped>
.legend-container >>> .chartjs-legend {
  @apply flex flex-row justify-center items-center;
}</style>
