<script>
import Chart from 'chart.js'
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

Chart.defaults.global.defaultFontFamily = 'Manrope, arial, sans-serif !important;'
Chart.Legend.prototype.afterFit = function () {
  this.height = this.height + 10
}

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ['chartData', 'options'],
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
