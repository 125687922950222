<template>
  <button
    class="font-button text-button text-white p-3 rounded-full"
    :class="getBackgroundColor()"
    @click="handleClick()"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: () => {
        return 'secondary'
      }
    }
  },
  methods: {
    getBackgroundColor () {
      return 'bg-' + this.color
    },
    handleClick () {
      this.$emit('click')
    }
  }
}
</script>
