<template>
  <div class="flex h-screen">
    <div class="m-auto">
      <div class="pb-12 py-12">
        <p class="font-semibold text-headline-4 text-center">
          Algo Research
        </p>
      </div>
      <div class="mx-12 pb-6 py-3">
        <p class="font-headline-6 text-headline-6 p-3 mx-6">
          Forgot password
        </p>
        <p class="font-light text-body-1 px-3 mx-6 text-gray-500">
          Please fill the below to continue
        </p>
      </div>
      <div class="mx-6 p-3">
        <InputTextBox
          class="px-0 py-2 mx-1"
          type="email"
          placeholder="Enter your email"
          @input="emailValue"
          :hide-errors="!emailError"
          :disabled="processing"
          warning="Please check again"
          warning-class="text-red-500"
        />
      </div>
      <div class="mx-12 p-3">
        <div class="py-3 mx-1">
          <ButtonSolidBox class="w-full h-full" color="primary" @click="resetPassword()"
            >Reset password</ButtonSolidBox
          >
          <p
            class="font-button text-button mx-auto text-center mt-1 text-gray-500 pt-8"
          >
            Back to
            <router-link :to="'/login'" class="text-gray-900 underline"
              >Login</router-link
            >
          </p>
        </div>
      </div>
    </div>
    <Alert :show="forgotPasswordDone"
      >Instructions to reset password will be sent to you. Please check your
      email.</Alert
    >
  </div>
</template>

<script>
import InputTextBox from '@/components/Inputs/InputTextBox.vue'
import ButtonSolidBox from '@/components/Buttons/ButtonSolidBox.vue'
import Alert from '@/components/Alert.vue'
import axios from 'axios'

export default {
  components: {
    InputTextBox: InputTextBox,
    ButtonSolidBox: ButtonSolidBox,
    Alert: Alert
  },
  data () {
    return {
      processing: false,
      emailResetValue: '',
      emailError: false,
      forgotPasswordDone: false
    }
  },
  methods: {
    emailValue (val) {
    // console.log(val)
      this.emailResetValue = val
    },
    resetPassword () {
      this.emailError = false
      if (this.emailResetValue !== '') {
        this.processing = true
        axios.post(process.env.VUE_APP_API_URL + '/v1/internal-user/forgot', { email: this.emailResetValue })
          .then(async (result) => {
            this.processing = false
            this.forgotPasswordDone = true

            setTimeout(() => {
              this.fogotPasswordDone = false
            }, 3000)
          })
          .catch((err) => {
            this.isRegisterFailed = true
            if (err.response) {
            // console.log(err.response)
            } else {
            // console.log(err)
            }
            this.processing = false
          })
      } else {
        this.emailError = true
      }
    }
  }
}
</script>
